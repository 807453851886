import { useEffect, useReducer, useState } from "react";
import Loader from "../../../../Components/Loader";

import { getAllFilesAnalysis } from "../../../../Services/Dashboard";
import { Card } from "../../../../UI/Card";
import DataTable from "../../../../UI/DataTable";

import { FiCheck, FiX } from 'react-icons/fi';
import { useHistory } from "react-router";
import CustomCheckbox from "../../../../Components/CustomCheckbox";
import { getAdminUsers } from "../../../../Services/User";
import styles from './style.module.css';

const initialDataSource = { consultantInMission: [], legalFiles: [], ids: [] };

const SELF_EMPLOYED = 'SELF_EMPLOYED';
const SOCIETY = 'SOCIETY';
const WAGE_PORTING = 'WAGE_PORTING';

const legalFilesReducer = (state, payload) => {
    switch (payload.action) {
        case 'REMOVE':
            return state.filter(structure => structure !== payload.value);
        default:
            return [...state, payload.value];
    }
}

const LegaLFiles = () => {
    const now = new Date();

    const [loading, setIsLoading] = useState(false);

    const history = useHistory();

    const [legalFiles, setLegalFiles] = useState(initialDataSource);
    const [filteredLegalFiles, setFilteredLegalFiles] = useState(initialDataSource);

    const [structures, dispatch] = useReducer(legalFilesReducer, '');
    const [managers, setManagers] = useState([]);
    const [selectedManagerId, setSelectedManagerId] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getAllFilesAnalysis().then(res => {
            setLegalFiles(res.data)
            setFilteredLegalFiles(res.data);
            setIsLoading(false);
        })
        if (!managers?.length) {
            getAdminUsers().then((res) => setManagers(res.data));
        }
    }, [managers]);

    const onChangeManagerHandler = (e) => {
        setSelectedManagerId(e.target.value);
    }

    const isLegalFileIssued = (doc, targetedDoc) => {
        return doc.type === targetedDoc && new Date(doc.expirationDate) >= now && doc.status === 'APPROVED';
    }

    const header = ['Consultant', 'R.C Pro', 'Vigilance Urssaf', 'Régularité Fiscale', 'Kbis/Insee', 'RIB', 'ID'];
    const title = 'Synthèse des documents légaux';

    let rows = <p className='text-center m-3'>Aucun résultat</p>;

    if (filteredLegalFiles?.length > 0) {
        const filteredLegalFilesByCriterias = filteredLegalFiles.filter((consultant) => {
            if (structures?.length > 0 && !structures.includes(consultant.juridicStructure)) {
                return false;
            }

            if (selectedManagerId && consultant.affiliateManagerId !== selectedManagerId) {
                return false;
            }
            return true;
        });

        rows = filteredLegalFilesByCriterias.map((consultant, index) => {
            const classes = index % 2 === 0 ? `flex d-flex ${styles['row-odd']} ${styles['rows']}` : `flex d-flex ${styles['rows']}`;

            return <div className={classes} key={index}>
                <div className={`${styles['href']}`}
                    onClick={() => history.push(`consultant/${consultant.consultantId}/documents`)}>
                    {consultant.fullName} {consultant.juridicStructure === 'WAGE_PORTING' && <span>(PORTAGE)</span>}
                </div>
                <div>
                    {consultant.legalFiles.find(doc => isLegalFileIssued(doc, "liability_insurance")) ? <FiCheck color="green" /> : <FiX color="red" />}
                </div>
                <div>
                    {consultant.legalFiles.find(doc => isLegalFileIssued(doc, 'urssaf')) ? <FiCheck color="green" /> : <FiX color="red" />}
                </div>
                <div>
                    {consultant.legalFiles.find(doc => isLegalFileIssued(doc, 'tax_certificate')) ? <FiCheck color="green" /> : <FiX color="red" />}
                </div>
                <div>
                    {consultant.legalFiles.find(doc => (doc.type === 'kbis' || doc.type === 'insee') && new Date(doc.expirationDate) >= now && doc.status === 'APPROVED') ? <FiCheck color="green" /> : <FiX color="red" />}
                </div>
                <div>
                    {consultant.legalFiles.find(doc => doc.type === 'bank_account_informations') ? <FiCheck color="green" /> : <FiX color="red" />}
                </div>
                <div>
                    {consultant.ids.find(doc => isLegalFileIssued(doc, 'front')) ? <FiCheck color="green" /> : <FiX color="red" />}
                </div>
            </div>
        })
    }

    const onChangeFinderHandler = (e) => {
        const targetName = e.target.value.toLowerCase();
        const filteredLegalFiles = legalFiles.filter((c) => c.fullName.toLowerCase().includes(targetName));
        setFilteredLegalFiles(filteredLegalFiles);
    }

    const dispatchNewStructure = (str) => {
        if (structures.includes(str)) {
            dispatch({ value: str, action: 'REMOVE' });
            return;
        }
        dispatch({ value: str })
    }

    return (<div>
        {loading && (
            <div
                className="w-100 position-relative card"
                style={{ height: "300px" }}
            >
                <Loader />
            </div>)}
        {!loading &&
            <div className="d-flex flex-column gap-2">
                <h2 className="header-2">{title}</h2>
                <div className="d-flex flex-row gap-3">
                    <div className='d-flex flex-column' style={{width:"100%"}}>
                            <label htmlFor='finder'>Finder :</label>
                        <input id='finder' className="default-input" placeholder='Critères : consultant' onChange={onChangeFinderHandler} type="text"></input>
                    </div>
                    <div className='d-flex flex-column' style={{width:"100%"}}>
                        <label>O.P Manager :</label>
                        <select className="default-input" onChange={onChangeManagerHandler} value={selectedManagerId}>
                            <option></option>
                            {managers.map((m, index) => <option value={m.id} key={index}>{m.fullName}</option>)}
                        </select>
                    </div>
                </div>
                <div className={styles['checkboxes']}>
                    <label>Structure juridique:</label>
                    <CustomCheckbox
                        onChange={() => dispatchNewStructure(SELF_EMPLOYED)}
                        value={structures.includes(SELF_EMPLOYED)}
                        name="selfEmployed"
                        label="Auto-Entreprise"
                    />
                    <CustomCheckbox
                        onChange={() => dispatchNewStructure(WAGE_PORTING)}
                        value={structures.includes(WAGE_PORTING)}
                        name="wagePorting"
                        label="Portage salarial"
                    />
                    <CustomCheckbox
                        onChange={() => dispatchNewStructure(SOCIETY)}
                        value={structures.includes(SOCIETY)}
                        name="society"
                        label="Société"
                    />
                </div>
                <div>
                    <DataTable header={header} title={title}>{rows}</DataTable>
                </div>
            </div>
        }
    </div>)
}


export default LegaLFiles;
