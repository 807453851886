import React, { useEffect, useRef, useState } from "react";
import { FiFlag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
// import LegalDocumentsTable from './../../../Components/DocumentLegauxTables/index';
import AddContract from "../../../Components/AddContract";
import ContratsTable from "../../../Components/ContractsTable";
import toast from "../../../Helpers/toast";
import { getContracts } from "../../../Services/Consultant";
import { notifyIssuedDocuments } from "../../../Services/Dashboard";
import AutoEntrepreneurTable from "./../../../Components/DocumentLegauxTables/AutoEntrepreneur/index";
import ConsultantTable from "./../../../Components/DocumentLegauxTables/Consultant/index";
import SocietyTable from "./../../../Components/DocumentLegauxTables/Societe/index";
import Loader from "./../../../Components/Loader/index";
import style from "./style.module.css";

const Documents = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const match = useRouteMatch();
  const [status, setStatus] = useState([]);
  // const [legalFiles, setLegalFiles] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [contractLoading, setContractLoading] = useState(false);

  const [showed, setShowed] = useState(false);

  const myForm = useRef(null);
  const missionInputRef = useRef("");

  const toggleOpen = (e) => {
    setShowed(!showed);
    if (
      !myForm.current.style.maxHeight ||
      myForm.current.style.maxHeight === "0px"
    ) {
      myForm.current.style.maxHeight = myForm.current.scrollHeight + "px";
    } else {
      myForm.current.style.maxHeight = null;
    }
  };

  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState("");

  const onMissionChange = (e) => {
    const str = "Choisir une mission";
    if (e.target.value !== str) {
      setSelectedMission(missions.find((m) => m.id === e.target.value));
    }

    setContractLoading(true);
    getContracts(
      e.target.value !== str
        ? {
            consultantId: match.params.id,
            missionId: e.target.value,
          }
        : { consultantId: match.params.id }
    ).then((res) => {
      setContracts(res.data);
      setContractLoading(false);
    });
  };

  const notifyIssuedDocumentsHandler = () => {
    notifyIssuedDocuments(currentConsultant.id).then(
      () => {
        toast(
          "success",
          "Relance des documents manquants ou expirés effectuée"
        );
      },
      (error) => toast("error", error.reason)
    );
  };

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      setStatus(currentConsultant.society?.juridicStructure);
      // setLegalFiles(currentConsultant.documents);
      setMissions(currentConsultant.missions);
      setLoading(false);
    }
  }, [currentConsultant]);

  const isMissionSelected =
    missionInputRef &&
    missionInputRef.current &&
    missionInputRef.current.value !== "Choisir une mission";
  const contractTable = isMissionSelected && (
    <div>
      {contractLoading && (
        <div
          className="w-100 position-relative card"
          style={{ height: "300px" }}
        >
          <Loader />
        </div>
      )}
      {!contractLoading && (
        <ContratsTable contracts={contracts} setContracts={setContracts} />
      )}
      <div className="col-3 m-auto my-4">
        <button className="default-btn" onClick={(e) => setOpen(true)}>
          Ajouter un contrat
        </button>
      </div>
    </div>
  );

  const legalSituation = (
    <div className="col-12 d-flex flex-row gap-3">
      <div className="d-flex flex-column w-100">
        {currentConsultant?.society && (
          <>
            <div className="d-flex flex-row">
              <div className="icon-container">
                <FiFlag />
              </div>
              <div className="d-flex flex-column mx-3">
                <div className="wow-subtitle green-text">Situation légale</div>
                <div className="wow-body">
                  <span>
                    {currentConsultant?.society?.type === "AUTO_ENTREPRENEUR"
                      ? "Auto entreprise"
                      : currentConsultant?.society?.juridicStructure ===
                        "SOCIETY"
                      ? "Société"
                      : "Portage salarial"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="wow-subtitle bold w-100 d-flex flex-row justify-content-between cursor-pointer mt-3"
              onClick={toggleOpen}
            >
              Les informations de l'entreprise
              <span>{showed ? "-" : "+"}</span>
            </div>
            <form
              ref={myForm}
              className={`row align-items-end justify-content-center ${style["accordion-form"]}`}
            >
              {currentConsultant.society.juridicStructure === "WAGE_PORTING" ? (
                <>
                  <div className="col-12 col-md d-flex flex-column mb-3 container">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="label"
                    >
                      Nom de la societé de portage
                    </label>
                    <input
                      id="label"
                      name="label"
                      value={currentConsultant.society.label}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="responsiblePersonName"
                    >
                      Nom du responsable de la société
                    </label>
                    <input
                      id="responsiblePersonName"
                      name="responsiblePersonName"
                      value={currentConsultant.society.responsiblePersonName}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="type"
                    >
                      Type de société
                    </label>
                    <input
                      id="type"
                      name="type"
                      value={
                        currentConsultant.society.type === "AUTO_ENTREPRENEUR"
                          ? "Auto entrepreneur"
                          : currentConsultant.society.type
                      }
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="occupation"
                    >
                      Fonction
                    </label>
                    <input
                      id="occupation"
                      name="occupation"
                      value={currentConsultant.society.occupation}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="label"
                    >
                      Nom de la société
                    </label>
                    <input
                      id="label"
                      name="label"
                      value={currentConsultant.society.label}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="rcs"
                    >
                      Ville RCS
                    </label>
                    <input
                      id="rcs"
                      name="rcs"
                      value={currentConsultant.society.rcs}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="siret"
                    >
                      SIRET
                    </label>
                    <input
                      id="siret"
                      name="siret"
                      value={currentConsultant.society.siret}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="w-100"></div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="address.street"
                    >
                      Adresse siège social
                    </label>
                    <input
                      id="address.street"
                      name="address.street"
                      value={currentConsultant.society.address.street}
                      className="default-input"
                      type="text"
                      placeholder="Adresse"
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <input
                      id="address.postalCode"
                      name="address.postalCode"
                      value={currentConsultant.society.address.postalCode}
                      className="default-input"
                      type="text"
                      placeholder="Code Postal"
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <input
                      id="address.city"
                      name="address.city"
                      value={currentConsultant.society.address.city}
                      className="default-input"
                      type="text"
                      placeholder="Ville"
                      disabled
                    />
                  </div>
                  {currentConsultant.society.tva && (
                    <div className="col-12 d-flex flex-column mb-3 ">
                      <label
                        className="small-text bold mb-2 cursor-pointer"
                        htmlFor="tva"
                      >
                        N° de TVA
                      </label>
                      <input
                        id="tva"
                        name="tva"
                        value={currentConsultant.society.tva}
                        className="default-input"
                        type="text"
                        disabled
                        placeholder="TVA"
                      />
                    </div>
                  )}
                </>
              )}
            </form>
            <div className="col-12 d-flex flex-row gap-3">
              {status === "SELF_EMPLOYED" ? (
                <AutoEntrepreneurTable />
              ) : status === "SOCIETY" ? (
                <SocietyTable />
              ) : (
                <ConsultantTable />
              )}
            </div>
            <div className={`${style["action"]}`}>
              <button
                className="default-btn"
                onClick={notifyIssuedDocumentsHandler}
              >
                Relancer documents manquants ou expirés
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="row card p-5 gap-5">
      {loading ? (
        <div className="col-12 position-relative" style={{ height: "300px" }}>
          <Loader />
        </div>
      ) : (
        <>
          {open && (
            <AddContract
              show={open}
              onClose={(e) => setOpen(false)}
              mission={selectedMission}
              setContracts={setContracts}
            />
          )}

          {legalSituation}

          <div className="col-12 d-flex flex-column gap-4">
            <div className="w-100 d-flex flex-row justify-content-between align-items-end wow-subtitle green-text">
              Contrats et avenants
            </div>
            <div className="w-100 d-flex flex-column gap-3">
              <div className="small-text bold">Choisir une mission</div>
              <select
                className="default-input"
                onChange={onMissionChange}
                placeholder="Choisir une mission"
                ref={missionInputRef}
              >
                <option value="Choisir une mission">Choisir une mission</option>
                {missions.map((m, i) => {
                  return (
                    <option key={i} value={m.id}>
                      {m.label}
                    </option>
                  );
                })}
              </select>
            </div>
            {contractTable}
          </div>
        </>
      )}
    </div>
  );
};

export default Documents;
